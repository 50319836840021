import { createContext, FC, useCallback, useEffect, useState } from 'react';

import themeService from './theme.service';
import { Theme, ThemeContextValue, ThemeProviderProps } from './theme.types';

export const ThemeContext = createContext<ThemeContextValue | null>(null);

export const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
  observe,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(themeService.getIsDarkMode());

  const changeTheme = useCallback((theme: Theme) => {
    themeService.setTheme(theme);
    setIsDarkMode(themeService.getIsDarkMode());
  }, []);

  useEffect(() => {
    // When we switch from observe/not observe set again the newest value
    setIsDarkMode(themeService.getIsDarkMode());

    if (!observe) return;

    const cb: MutationCallback = (mutationList, _observer) => {
      for (const mutation of mutationList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          setIsDarkMode(themeService.getIsDarkMode());
        }
      }
    };

    const observer = new MutationObserver(cb);

    observer.observe(document.documentElement, {
      attributeFilter: ['class'],
      attributes: true,
      characterData: false,
      childList: false,
      subtree: false,
      characterDataOldValue: false,
      attributeOldValue: false,
    });

    return () => {
      observer.disconnect();
    };
  }, [observe]);

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        changeTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
