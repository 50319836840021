import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import type { VasHostConfigDto } from '@bluecodecom/ui-sdk-abstraction';

import getStringifiedObject from '@bluecodecom/utils/getStringifiedObject';

import { nativeService } from '../../service';
import { VasHostConfigContextValue } from './VasHostConfig.types';

export const VasHostConfigContext =
  createContext<VasHostConfigContextValue | null>(null);

const VasHostConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<VasHostConfigDto>({
    currentLanguage: 'DE',
    hasSuggestionsEnabled: false,
    isDarkThemeEnabled: false,
    isLocationAccepted: false,
    isLocationEnabled: false,
    isPushNotificationAccepted: false,
    isSuggestionsTabEnabled: false,
    isVasEnabled: false,
  });

  useEffect(() => {
    window.setVasHostConfig = (payload: string) => {
      const data = getStringifiedObject(payload) as VasHostConfigDto;

      setData((d) => ({
        ...d,
        ...data,
      }));

      setIsLoading(false);
    };

    nativeService.requestVasHostConfig();
  }, []);

  return (
    <VasHostConfigContext.Provider value={{ isLoading, ...data }}>
      {children}
    </VasHostConfigContext.Provider>
  );
};

export default VasHostConfigProvider;
