import { Theme } from './theme.types';

const DARK_MODE_CLASS_NAME = 'dark';

export class ThemeService {
  private static instance: ThemeService;

  public static getInstance(): ThemeService {
    if (!ThemeService.instance) {
      ThemeService.instance = new ThemeService();
    }

    return ThemeService.instance;
  }

  public setTheme(theme: Theme) {
    document.documentElement.classList[theme === 'dark' ? 'add' : 'remove'](
      DARK_MODE_CLASS_NAME,
    );
  }

  public getIsDarkMode() {
    return document.documentElement.classList.contains(DARK_MODE_CLASS_NAME);
  }
}

const themeService = ThemeService.getInstance();

export default themeService;
