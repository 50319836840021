import i18next from 'i18next';

import { Resources } from './i18n.types';

export const updateTranslations = (
  translations: object,
  ns = 'translation',
  language = '',
) => {
  const currentLanguage = i18next.language;
  i18next.addResourceBundle(
    language || currentLanguage,
    ns,
    translations,
    true,
    true,
  );
  return i18next.changeLanguage(currentLanguage);
};

export const addTranslations = (ns: string, resources: Resources) => {
  if (!i18next?.addResourceBundle) {
    return console.info(
      `i18next is not initialized! Couldn't add "${ns}" namespace. In root of front application (before adding translations) call initI18n()`,
    );
  }

  Object.entries(resources).forEach(([lng, translations]) =>
    i18next.addResourceBundle(lng, ns, translations, true, false),
  );
};
