import { useContext } from 'react';

import { LoaderContext } from '../../providers/Loader';

const useLoaderContext = () => {
  const ctx = useContext(LoaderContext);

  if (!ctx) {
    throw new Error(
      'The useLoaderContext hook must be used within a LoaderContext.Provider',
    );
  }

  return ctx;
};

export default useLoaderContext;
