import { is } from 'ramda';

const isObject = is(Object);
const isString = is(String);

const getStringifiedObject = (data: unknown): any => {
  try {
    if (isString(data)) {
      return JSON.parse(data);
    } else if (isObject(data)) {
      return data;
    } else {
      return {};
    }
  } catch {
    return {};
  }
};

export default getStringifiedObject;
