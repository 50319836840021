import { useEffect } from 'react';

import { useLoaderContext } from '../../hooks';

const Loading = () => {
  const { showLoader, hideLoader } = useLoaderContext();

  useEffect(() => {
    showLoader();

    return () => {
      hideLoader();
    };
  }, [hideLoader, showLoader]);

  return null;
};

export default Loading;
