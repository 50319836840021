import { useEffect, useRef } from 'react';

import { trackingService, EventName, TrackingEvents } from '../../service';

export default function useTrackEvent<T extends EventName>(
  event: T,
  ...params: TrackingEvents[T] extends null ? [undefined?] : [TrackingEvents[T]]
) {
  const sentRef = useRef(false);

  useEffect(() => {
    if (sentRef.current) {
      return;
    }
    sentRef.current = true;
    trackingService.trackEvent(event, ...params);
  }, [event, params]);
}
