import { createContext, FC, PropsWithChildren, useCallback } from 'react';

import { LoaderContextValue, LoaderProviderProps } from './Loader.types';

export const LoaderContext = createContext<LoaderContextValue | null>(null);

const LoaderProvider: FC<PropsWithChildren<LoaderProviderProps>> = ({
  elementId = 'initial-loader',
  children,
}) => {
  const hideLoader = useCallback(() => {
    document
      .getElementById(elementId)
      ?.classList.add('opacity-0', 'pointer-events-none');
  }, [elementId]);

  const showLoader = useCallback(() => {
    document
      .getElementById(elementId)
      ?.classList.remove('opacity-0', 'pointer-events-none');
  }, [elementId]);

  return (
    <LoaderContext.Provider value={{ hideLoader, showLoader }}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
