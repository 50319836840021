import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ContractInfoDto } from '@bluecodecom/ui-sdk-abstraction';

import { nativeService } from '@bluecodecom/native';

import getStringifiedObject from '@bluecodecom/utils/getStringifiedObject';

import { ContractInfoContextValue } from './ContractInfo.types';

const CHECK_CONTRACT_TIMEOUT = 3000;

export const ContractInfoContext =
  createContext<ContractInfoContextValue | null>(null);

const ContractInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [contractInfo, setContractInfo] = useState<ContractInfoDto | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSupported, setIsSupported] = useState(true);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const timeoutRef = useRef(-1);

  // const handleSetContractInfo = useCallback(
  //   (stringifiedContractInfo?: string | null) => {
  //     if (!stringifiedContractInfo) {
  //       return false;
  //     }

  //     try {
  //       setContractInfo(
  //         JSON.parse(stringifiedContractInfo || '') as ContractInfo,
  //       );

  //       window.clearTimeout(timeoutRef.current);
  //       setIsLoading(false);
  //       return true;
  //     } catch {
  //       // fallthrough
  //     }
  //     return false;
  //   },
  //   [],
  // );

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      setIsLoading(false);
      setIsTimedOut(true);
      console.log('ContractInfo time out');
    }, CHECK_CONTRACT_TIMEOUT);

    if (
      !nativeService.isSdkAtLeast('7.2.19') ||
      !nativeService.isNativeEnvironment()
    ) {
      console.log('ContractInfo not supported');
      setIsLoading(false);
      setIsSupported(false);
      return;
    }

    // handleSetContractInfo(window.stringifiedContractInfo);

    window.setContractInfo = (payload: string) => {
      console.log('ContractInfo setting', payload);
      const data = getStringifiedObject(payload) as ContractInfo;
      setContractInfo(data);
      setIsLoading(false);
      window.clearTimeout(timeoutRef.current);
    };

    nativeService.requestContractInfo();
  }, []);

  if (isLoading) {
    console.log('ContractInfo isLoading');
    return null;
  }

  return (
    <ContractInfoContext.Provider
      value={{ contractInfo, isSupported, isTimedOut }}
    >
      {children}
    </ContractInfoContext.Provider>
  );
};

export default ContractInfoProvider;
