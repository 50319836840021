import type { Config, Mixpanel } from 'mixpanel-browser';

import { DeploymentTarget } from '@bluecodecom/types';

const isProduction = process.env['NODE_ENV'] === 'production';
const isTest = process.env['NODE_ENV'] === 'test';

const DEPLOYMENT_TARGET_ENV = process.env[
  'NX_PUBLIC_DEPLOYMENT_TARGET'
] as DeploymentTarget;

const API_HOST: { [deploymentTarget in DeploymentTarget]: string } = {
  'bci-dev': 'https://client-events.spt-payments.mobi/proxy',
  'bcx-dev': 'https://client-events.spt-payments.mobi/proxy',
  'bci-int': 'https://client-events.spt-payments.biz/proxy',
  'bcx-int': 'https://client-events.spt-payments.biz/proxy',
  'bci-prod': 'https://client-events.spt-payments.com/proxy',
  'bcx-prod': 'https://client-events.spt-payments.com/proxy',
  'ngc-int': '',
  'ngx-int': '',
  'ngc-prod': '',
  'ngx-prod': '',
  'empsa-dev': '',
  'empsa-int': '',
  'empsa-prod': '',
  local: '',
};

class TrackingService {
  private static instance: TrackingService;
  private _mixpanel: Mixpanel | null = null;
  private initialized = false;
  private queue: Array<{ event: string; props: Record<string, unknown> }> = [];

  get mixpanel() {
    return this._mixpanel;
  }

  public static getInstance() {
    if (!TrackingService.instance) {
      TrackingService.instance = new TrackingService();
    }

    return TrackingService.instance;
  }

  private log(event: string, props: Record<string, unknown>) {
    console.log(
      '%c Mixpanel Event %c ' + event,
      'background: #4284fb; color: white;',
      'color: black',
      props,
    );
  }

  public init(token = '', id = '', config: Partial<Config> = {}) {
    if (!isProduction || this.initialized || !token) {
      this.initialized = true;

      this.queue.forEach(({ event, props }) => this.trackEvent(event, props));
      this.queue = [];

      return;
    }

    import('mixpanel-browser')
      .then(({ default: mp }) => {
        mp.init(token, {
          ...config,
          api_host:
            API_HOST[DEPLOYMENT_TARGET_ENV] || 'https://api.mixpanel.com',
          cross_subdomain_cookie: false,
          loaded: (mixpanel) => {
            this._mixpanel = mixpanel;

            this._mixpanel.identify(id);
            this._mixpanel.register({
              user_agent: navigator.userAgent || 'Unknown userArgent',
            });

            this.initialized = true;

            this.queue.forEach(({ event, props }) =>
              this.trackEvent(event, props),
            );
            this.queue = [];
          },
        });
      })
      .catch((error) => {
        console.error('Error initializing Mixpanel', error);
      });
  }

  public trackEvent(event: string, props: Record<string, unknown> = {}) {
    if (!this.initialized) {
      return this.queue.push({ event, props });
    }

    if (isProduction) {
      return this._mixpanel?.track(event, props);
    }

    if (!isTest) {
      return this.log(event, props);
    }
  }
}

const trackingService = TrackingService.getInstance();

export default trackingService;
