import { useContext } from 'react';

import { ContractInfoContext } from '../../providers/ContractInfo';

const useContractInfoContext = () => {
  const ctx = useContext(ContractInfoContext);

  if (!ctx) {
    throw new Error(
      'useContractInfoContext must be used inside ContractInfoContext',
    );
  }

  return ctx;
};

export default useContractInfoContext;
