import { useContext } from 'react';

import { ThemeContext } from './theme.provider';

export const useTheme = () => {
  const ctx = useContext(ThemeContext);

  if (!ctx) {
    throw new Error(
      'The useTheme hook must be used within a ThemeContext.Provider',
    );
  }

  return ctx;
};

export default useTheme;
