import { useContext } from 'react';

import { VasHostConfigContext } from '../../providers/VasHostConfig';

const useVasHostConfigContext = () => {
  const ctx = useContext(VasHostConfigContext);

  if (!ctx) {
    throw new Error(
      'useVasHostConfigContext must be used inside VasHostConfigContext',
    );
  }

  return ctx;
};

export default useVasHostConfigContext;
