import { FC, PropsWithChildren, useEffect } from 'react';

import useLoaderContext from '../../hooks/useLoaderContext';

const Loaded: FC<PropsWithChildren> = ({ children }) => {
  const { hideLoader } = useLoaderContext();

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  return <>{children}</>;
};

export default Loaded;
